import sectionsManager from 'util/sections-manager';

import s from 'styles/sections/job-openings';
import Button from 'components/button';
import { useEffect, useState } from 'react';
import Loader from 'components/loader';
import wpApi from 'util/wp-api';
import { revealLeftVariants } from 'animations/framer-motion';
import { useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';

function JobOpenings({fields}) {
    const { headline, display_filters } = fields;
    const control = useAnimation()
    const [ref, inView] = useInView()

    useEffect(() => {
        if (inView) {
            control.start('visible');
        }
    }, [control, inView]);

    const [isLoading, setIsLoading] = useState(false);
    const [query, setQuery] = useState(fields.query)
    const [jobs, setJobs] = useState(fields.query.posts)
    // const [location, setLocation] = useState('');
    // const [category, setCategory] = useState('');
    const [isFiltering, setIsFiltering] = useState(false);

    const locations = [
        'New York',
        'Sao Paulo'
    ]

    const categories = [
        'Operation',
        'IT'
    ]

    const fetchPosts = async () => {

        // Bail if there's no next page
        if (!query.pagination.next_query) return;

        setIsLoading(true);
        
        const response = await wpApi.request('query', query.pagination.next_query);

        setJobs(jobs => [...jobs, ...response.posts]);
        setQuery(response);
        setIsLoading(false);
        setIsFiltering(false);
    }

    const loadMore = event => {
        event.preventDefault();

        setIsFiltering(false);
        fetchPosts();
    }
    
    // TODO: implement filtering
    const filter = async (event, filterState) => {

        filterState(event.target.value);
        setIsFiltering(true);
    }

    const jobListing = () => (
        jobs.map((job, index) => (
            <s.Job key={index}>
                <s.JobHeader>
                    <s.JobTitle>{job.title}</s.JobTitle>
                    <s.JobCategory>{job.fields.role}</s.JobCategory>
                </s.JobHeader>
                <s.JobType>{job.fields.location}</s.JobType>
                {job.fields.lever_link ? (
                    <Button
                        styleName='light'
                        href={job.fields.lever_link.url}
                        iconName={'arrow-right'}
                    >{job.fields.lever_link.title}</Button>
                ) : null }
            </s.Job>
        )));

	return (
		<s.Section id={fields.anchor_id}>
            <s.FirstDots />
            <s.SecondDots />
			<s.Wrapper>
				<s.Header>
                    {headline ? <s.Headline {...revealLeftVariants} ref={ref} animate={control}>{headline}</s.Headline> : null}
                    {/* TODO: implement filtering */}
                    {false ? (<s.Filters>
                        <s.SelectWrapper>
                            <s.Select name="location" onChange={event => filter(event, setLocation)}>
                                <option selected value="">Location</option>
                                {locations.length ? (locations.map(location => 
                                    <option value={location}>{location}</option>
                                )) : null}
                            </s.Select>
                            <s.ChevronDown />
                        </s.SelectWrapper>
                        <s.SelectWrapper>
                            <s.Select name="categories" onChange={event => filter(event, setCategory)}>
                                <option selected value="">Department</option>
                                {categories.length ? (categories.map(category => 
                                    <option value={category}>{category}</option>
                                )) : null}
                            </s.Select>
                            <s.ChevronDown />
                        </s.SelectWrapper>
                    </s.Filters>) : null}
                </s.Header>
                <s.JobListing>
                    {(!jobs.length || isFiltering) ?
                        <>
                            {isFiltering ? <s.Loader /> : null}
                            {!jobs.length && !isFiltering ? <s.NotFound>Nothing Found :(</s.NotFound> : null}
                        </>
                    : jobListing()}
                </s.JobListing>

                {(query?.posts_count == jobs.length) ? null : (<s.LoadMoreWrapper>
                    {(isLoading && !isFiltering) ? (<Loader />) : null}
                    {!isLoading && jobs.length ? (<s.LoadMore
                        styleName='light'
                        type='button'
                        onClick={loadMore}>
                            See more positions
                        </s.LoadMore>)
                    : null}
                </s.LoadMoreWrapper>)}
			</s.Wrapper>
		</s.Section>
	);
}

sectionsManager.register('job-openings', JobOpenings);