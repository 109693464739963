import sectionsManager from 'util/sections-manager';

import s from 'styles/sections/careers-callout';
import Image from 'next/image';
import Button from 'components/button';
import Link from 'next/link';
import { revealLeftVariants } from 'animations/framer-motion';
import { useAnimation } from 'framer-motion';
import { useInView } from 'react-intersection-observer';
import { useEffect } from 'react';

function CareersCallout({fields}) {
    const {content, listing} = fields;
    const control = useAnimation()
    const [ref, inView] = useInView()

    useEffect(() => {
        if (inView) {
            control.start('visible');
        }
    }, [control, inView]);

	return (
		<s.Section id={fields.anchor_id}>
			<s.Wrapper size='large' noPadding={true}>
                <s.MobileFirstDots />
                <s.MobileSecondDots />
                <s.Content
                        {...revealLeftVariants}
                        ref={ref}
                        animate={control}
                >
                    {content.overline ? (<s.Overline>{content.overline}</s.Overline>) : null}
                    {content.headline ? (<s.Headline>{content.headline}</s.Headline>) : null}
                    {content.small_text ? (<s.SmallText dangerouslySetInnerHTML={{__html: content.small_text}} />) : null}
                </s.Content>
                <s.Listing>
                    <s.FirstDots />
                    <s.SecondDots />
                    <Image src={listing.background_image.url} alt={listing.background_image.alt} layout='fill' objectFit='cover' />
                    {listing.posts.length ? (listing.posts.map((job, jobKey) => {
                        const {fields} = job;

                        return (
                            <Link passHref key={jobKey} href={job.permalink || '#'} target='_self'>
                                <s.Job>
                                    <s.JobContent>
                                        {job.title ? (<s.JobTitle>{job.title}</s.JobTitle>) : null}
                                        {fields.role ? (<s.JobRole>{fields.role}</s.JobRole>) : null}
                                    </s.JobContent>
                                    <s.Icon className='icon' />
                                    <s.Button
                                        styleName={'light'}
                                        href={job.permalink}
                                        target={'_self'}
                                        iconName={'arrow-right'}>
                                        Apply Now
                                    </s.Button>
                                </s.Job>
                            </Link>
                        )
                    })) : null}
                    {listing.cta.title && (
                        <s.ButtonWrapper>
                            <Button
                                styleName={'light'}
                                href={listing.cta.url}
                                target={listing.cta.target}
                                iconName={'arrow-right'}>
                                {listing.cta.title}
                            </Button>
                        </s.ButtonWrapper>
                    )} 
                </s.Listing>
            </s.Wrapper>    
		</s.Section>
	);
}

sectionsManager.register('careers-callout', CareersCallout);